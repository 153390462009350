.photo-lp-main {
    text-align: center;
    background-color: #18191b;
  }

  .photo-header{
    text-align: center;
  }

  #ig-logo{
    margin-top: 20px;
    width: 35px;
    height: 35px;
    margin-bottom: 20px;
  }
  
  #desctext {
    color: white;
    text-align: center;
    margin-top: 0;
  }
  
  .tophomebutton{
    margin-top: 30px !important;
    padding: 8px 20px !important;
  }

  .homebutton{
    margin-right: 20px;
  }

  .homebutton, .tophomebutton {
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    padding: 8px 24px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .tophomebutton:hover, .homebutton:hover {
    background-color: #2e6bff;
  }

  #photoheader {
    color: white;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .captions {
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 5px;
    font-size: calc(4px + 1vmin);
    font-family: 'Lato', sans-serif;

    /*
    background-color: #2f3135;
    border-radius: 10px;
    padding: 3px 12px;
    */
  }
  
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  
  .image-container > img {
    height: auto; /* Remove the fixed height for the image */
    object-fit: scale-down;
    border: 3px solid #000000;
    border-radius: 5px;
    width: 46vw;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Arrange elements in 2 columns */
    grid-gap: 10px; /* Adjust the gap between elements */
    justify-content: center;
  }
  