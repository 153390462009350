/* cleared */
html {
  scroll-behavior: smooth;
}

.photopage:hover{
  filter: brightness(120%);
}

.centerpiece{
  margin-bottom: 20px;
}

#headerbanner.scrolled {
  background-color: rgba(24, 25, 27, 0.90); /* Darkens the background */
  transition: background-color 1.0s ease; /* Smooth transition */
}

/* Make text color white when scrolled */
#headerbanner.scrolled .header {
  color: white; /* Change text color to white for elements with the 'header' class */
}

#headerbanner.scrolled .navbuttons button {
  color: white; /* Change text color to white for buttons inside .navbuttons */
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.LandingPage {
  text-align: center;
  background-image: url("../share/photos/bg-blur.jpg");
  background-color: #18191b ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#lp-head, #lp-text{
  margin: 0;
  padding: 0;
  margin-bottom: 10px;

}

.landingPageText{
  margin-top: 60px;
}

#JMbutton{
  background-color: transparent;

}

#JMbutton:hover{
  cursor: pointer;

}

.navbuttons > *{
  background-color: transparent;
}

#headerbanner {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  color: #fff;
  align-items: center;
  z-index: 1;
  padding: 0.2rem 1%;
  margin-right: 10px;
}
#JMbutton{
  font-weight: bold;
}
#bannerbutton{
  font-weight: bold;
  font-size: 12px;
}



.header{
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
  color: black;
  font-size: calc(10px + 1vmin);
  font-family: 'Quicksand', sans-serif;
  padding: 0.9vh 2.2vh;
  margin-top: 0px;
  margin-bottom: 8px;
  border: none;
  outline: none;
  transition: color 0.3s ease;


}

.header:hover{
  color: #40a6ff;
  cursor: pointer;
}

#resumeheader{
  border: 2px solid #dcdddf !important;
  color: white;
  margin-left: 13px;

}

#resumehead:hover, #photobutton:hover, #exploreButton:hover{
  transition: color 0.5s ease;
  
}

#resumeheader:hover{
  color: #40a6ff;
  border: 2px solid #40a6ff !important;
}

.buttons {
  margin-top: 20vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.buttons button {
  margin-top: 10px;
  margin-left: 10px;
}


#photobutton:hover, #exploreButton:hover{
  color: #40a6ff;
  border: 2px solid #40a6ff !important;
  cursor: pointer;
}

#exploreButton {
  font-size: 15px;
  padding: 12px 48.5px;
}

#photobutton{
  font-size: 15px;
  padding: 12px 30px;
}

#exploreButton, #photobutton{
  border: none;
  transition: background-color 0.3s ease;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 40px;
  color: rgb(255, 255, 255);
  border: 2px solid #ffffff !important;
}


#dp {
  padding-top: 20px;
  width: 20vh;
  height: 20vh;
}

#bike{
  height: 5vmin;
  pointer-events: auto;
  width: 7hh;
  height: 7vh;
}

.LP-header {
  /* bg size */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: white;
  flex-direction: column;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveLeft{
  0% {
    transform: translateX(28vw);
  }
  100% {
    transform: translateX(-28vw);
  }
}

@keyframes moveRight{
  0% {
    transform: translateX(-28vw);
  }
  100% {
    transform: translateX(28vw);
  }
}

.shake{
  animation: shake 2s;
}
/* this was taken from w3 schools */
@keyframes shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(-10px, 0) rotate(-5deg); }
  20% { transform: translate(10px, 0) rotate(5deg); }
  30% { transform: translate(-10px, 0) rotate(-5deg); }
  40% { transform: translate(10px, 0) rotate(5deg); }
  50% { transform: translate(-10px, 0) rotate(-5deg); }
  60% { transform: translate(10px, 0) rotate(5deg); }
  70% { transform: translate(-10px, 0) rotate(-5deg); }
  80% { transform: translate(10px, 0) rotate(5deg); }
  90% { transform: translate(-10px, 0) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}


.blue-bike{
  animation: moveRight 10s linear infinite;
}

.red-bike {
    animation: moveLeft 7s linear infinite;
}

.green-bike {
    animation: moveLeft 9s linear infinite;
}

.blue-bike:hover, .green-bike:hover {
  animation-play-state: paused;
}

#bike:hover{
  filter: brightness(120%);
}