
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #18191b;
  color: white;
}
#JMbutton, #exploreButton {
  z-index: 10;
}


/* Main content container */
#projects, #education, #experience, #aboutme {
  max-width: 100%; /* Allow full width of the screen */
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box; /* Include padding and border in element width */
  word-wrap: break-word; /* Break long words to prevent text overflow */
}

/* Ensure background color and other general styles */
.Experience, .Projects, .Education, .AboutMe, .Contact {
  background-color: #18191b; /* Ensure no white is visible */
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  box-sizing: border-box; /* Prevent any overflow from padding */
  color: white; /* Reinforce white text within these sections */
}

/* Footer styling */
.Footer {
  background-color: #18191b;
}

.Footer > * {
  color: white; /* Keep footer text white */
}

.flinks > img {
  filter: invert(100%);
}

.share, .shareicon {
  width: 40px;
  height: 40px;
}

.links {
  margin-right: 10px;
  margin-left: 5px;
  filter: invert(100%);
}

#demos {
  color: white;
}

h2 {
  font-size: 2.5em;
  text-align: center;
  color: white; /* Ensure headings are white */
}

h3 {
  font-size: 1.8em;
  color: white; /* Ensure subheadings are white */
}

#arrowbutton {
  display: block;
  margin: auto;
  background-color: #0077cc;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  font-family: sans-serif;
  width: 35px;
  height: 35px;
  zoom: 1.5;
}

#arrowbutton:hover {
  background-color: #1584d3;
  cursor: pointer;
}

a {
  color: #ffffff; /* Ensure links are white */
  text-decoration: underline;
}

a:hover {
  color: #0059ff;
  cursor: pointer;
}

.Contact {
  text-align: center;
}

#topButton {
  font-size: calc(8px + 1vmin);
  padding: 6px 12px;
  margin-top: 20px;
  border: none;
  transition: background-color 0.3s ease;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  color: white; /* Ensure button text is white */
}

#topButton:hover {
  background-color: #0059ff;
  cursor: pointer;
}

#foot {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 12vh;
  color: white; /* Ensure footer text is white */
}

li {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: white; /* Ensure list items are white */
}

/* Portrait-style (phone or tablet in vertical mode) - Apply all the recent changes here */
@media only screen and (orientation: portrait) {
  html, body {
      width: 100%;
  }

  #projects, #education, #experience, #aboutme {
      max-width: 100%; /* Allow full width of the screen */
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      box-sizing: border-box;
      word-wrap: break-word; /* Prevent text overflow */
  }

  .Experience, .Projects, .Education, .AboutMe, .Contact {
      width: 100%;
      padding-bottom: 20px;
      padding-top: 20px;
      box-sizing: border-box; /* Prevent overflow from padding */
      color: white; /* Ensure text is white in portrait mode */
  }

  /* Fix image sizes and remove inverted colors */
  img, .share, .shareicon {
      max-width: 100%; /* Ensure images and icons are responsive */
      height: auto; /* Maintain aspect ratio */
      filter: none; /* Remove inverted color */
  }

  .flinks > img {
      max-width: 50px; /* Limit flinks icon size */
      height: auto;
      filter: none; /* Remove inverted colors */
  }

  h2 {
      font-size: 2em; /* Smaller heading for smaller screens */
      color: white; /* Ensure headings are white */
  }

  h3 {
      font-size: 1.5em; /* Adjust subheading size */
      color: white; /* Ensure subheadings are white */
  }

  li {
      font-size: 1em; /* Smaller list items on mobile */
      color: white; /* Ensure list items are white */
  }

  #arrowbutton {
      zoom: 1.2; /* Adjust button size for smaller screens */
  }

  #topButton {
      font-size: calc(7px + 1vmin); /* Adjust button font size for mobile */
      padding: 5px 10px;
  }

  #foot {
      padding: 10px;
      color: white; /* Ensure footer text is white */
  }

  /* Extra small screens (like very small phones) */
  @media screen and (max-width: 480px) {
      h2 {
          font-size: 1.8em;
          color: white; /* Ensure headings are white */
      }

      h3 {
          font-size: 1.2em;
          color: white; /* Ensure subheadings are white */
      }

      li {
          font-size: 0.9em;
          color: white; /* Ensure list items are white */
      }

      .share, .shareicon {
          width: 25px;
          height: 25px; /* Smaller icons for extra small screens */
      }
  }
}

/* Desktop and landscape only styles: Restrict the text width to 2/3 of the screen */
@media only screen and (min-width: 1024px) and (orientation: landscape) {
  #projects, #education, #experience, #aboutme {
      max-width: 66%; /* Make the content take up 2/3 of the screen */
      margin: 0 auto; /* Center the content with auto margins */
      padding: 20px; /* Maintain padding for spacing */
  }
}

/* Portrait-style (phone or tablet in vertical mode) - Apply all the recent changes here */
@media only screen and (orientation: portrait) {
  /* Fix image sizes and remove inverted colors */
  img, .share, .shareicon {
    max-width: 300%; /* Ensure images and icons are responsive */
    height: auto; /* Maintain aspect ratio */
    filter: none; /* Remove inverted color */
  }

  .flinks > img {
    max-width: 50px; /* Limit flinks icon size */
    height: auto;
    filter: none; /* Remove inverted colors */
  }

  /* Adjust logos inside links */
  a img {
    max-width: 80px; /* Set a max width to prevent logos from getting too large */
    height: auto; /* Ensure the aspect ratio is maintained */
  }
}

